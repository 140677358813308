<template>
  <div class="box">
    <div class="l_nav">
      <ul>
        <li>当前位置：</li>
        <li @click="goIndex"><a href="javascript:;">首页 </a></li>
        <li><span>>></span></li>
        <li>{{ params.name }}</li>
      </ul>
    </div>
    <div class="content">
      <template v-if="params.type == 1">
        <template v-if="isTwoNavigation">
          <div class="leftBox">
            <div class="title" :style="{background:color}">精彩图片</div>
            <ul class="imgBox">
              <template v-for="(item,index) in mapDepotList" @key="index">
                <li @click="goDetails('',item.id)" v-if="index<4" :key="index">
                  <el-image class="img" :src="echoImg(item.bannerUrl)" fit="fit"></el-image>
                  <div class="journalisTitle">{{item.journalisTitle}}</div>
                </li>
              </template>
            </ul>
          </div>
          <div class="rightBox">
            <dl v-for="(item,index) in dataList" :key="index" class="dl">
              <dt class="rTitle">{{item.navigationName}}<span @click="more(item.navigationName,item.id,item.navigationType)">更多>></span></dt>
              <dd v-for="(dates,idxs) in item.journalism" :key="idxs" class="dd">
                <span class="name" @click="goDetails(item.navigationName,dates.id)">{{dates.journalisTitle}}</span>
                <span class="time">{{toDate(dates.releaseTime, "yyyy-MM-dd")}}</span>
              </dd>
            </dl>
          </div>
        </template>
        <template v-else>
          <ul class="listBox">
            <li v-for="(item, index) in dates" :key="index" @click="goDetails(params.name,item.id)">
              <span class="circular" :style="'background:' + color"></span>
              <span class="liText">{{ item.journalisTitle }}</span>
              <span class="liTime">{{ toDate(item.releaseTime, "yyyy-MM-dd") }}</span>
            </li>
          </ul>
          <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-size="query.size" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
        </template>
      </template>
      <template v-else>
        <div class="title">{{ details.journalisTitle }}</div>
        <div class="title_m">
          <span>信息来源：{{ details.message }}</span>
          <span>发布日期：{{ toDate(details.createTime, "yyyy-MM-dd") }}</span>
        </div>
        <div class="ql-editor" v-html="text"></div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { selectNavigaionList, getTextarea, selectMapDepot } from "@/api/template";
import {getNavigationQueryId, getNavigationTwoList} from "../../../api/template";
export default {
  name: "List",
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      params: "",
      query: {
        current: 1,
        navigationId: "",
        navigationType: "",
        size: 15,
      },
      total: 0,
      dates: [],
      details: {},
      text: "",
      isTwoNavigation:false,
      dataList:[],
      mapDepotList:[]
    };
  },
  created() {
    // this.params = JSON.parse(window.sessionStorage.getItem("navigationValue"));
    this.params = this.$route.params
    this.dataList = []
    if(this.params.type == 1){
      if(this.params.isP == 'true'){
        this.getGetNavigationQueryId(this.params.id)
      }else {
        this.init()
      }
    }else {
      this.getSelectNavigaionList()
    }
  },
  methods: {
    init() {
      this.query.navigationId = this.params.id;
      this.query.navigationType = this.params.type;
      selectNavigaionList(this.query).then((res) => {
        if(res.code == 200){
          this.dates = res.data.records;
          this.total = res.data.total;
        }
      }).catch(() => {});
    },
    //获取子导航
    getGetNavigationQueryId(id){
      getNavigationQueryId(id).then(res=>{
        if(res.code == 200){
          if(res.data.witem.length > 0){
            this.isTwoNavigation = true
            this.getGetNavigationTwoList()
            this.getSelectMapDepot()
          }else {
            this.isTwoNavigation = false
            this.init()
          }
        }
      })
    },
    //获取二级导航及列表
    getGetNavigationTwoList(){
      getNavigationTwoList().then(res=>{
        if(res.code == 200){
          res.data.forEach(dates=>{
            if(this.params.id == dates.id){
              this.dataList = dates.witemVo
            }
          })
        }
      })
    },
    //获取精彩图片
    getSelectMapDepot(){
      let self = this
      selectMapDepot().then(res =>{
        if(res.code == 200){
          this.mapDepotList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    //获取单位概况数据
    getSelectNavigaionList(){
      this.query.navigationId = this.params.id;
      this.query.navigationType = this.params.type;
      selectNavigaionList(this.query).then((res) => {
        if(res.code == 200){
          this.details = res.data;
          this.getTextareaFun(res.data.journalisContent);
        }
      }).catch(() => {});
    },
    getTextareaFun(journalisContent) {
      let params = {
        journalisContent: journalisContent,
      };
      getTextarea(params).then((res) => {
        this.text = res.data.dataHtml;
      });
    },
    handleSizeChange(size) {
      this.query.size = size;
      this.init();
    },
    handleCurrentChange(current) {
      this.query.current = current;
      this.init();
    },
    goIndex() {
      this.$router.push({name:'template-seventh'});
    },
    more(navigationName,id,type){
      this.$router.push({
        params: {
          name: navigationName,
          id: id,
          type: type,
          isP: 'false'
        },
        name:'seventh-list',
      })
    },
    goDetails(navigationName,id) {
      let data = {
        name: navigationName,
        id: id,
      };
      window.sessionStorage.setItem("detailsValue", JSON.stringify(data));
      this.$router.push("/seventh-details");
    },
    goBack(){
      this.$router.go(-1)
    },
  },
};
</script>
<style lang="scss" scoped>
.box{
  width: 960px;
  min-height: 500px;
  margin: 0 auto;
  padding: 20px;
}
.l_nav {
  height: 40px;
  //background-color: #f7f7f7;
  border-bottom: 1px #838383 solid;
  color: #333;
  ul {
    width: 1000px;
    margin: 0 auto;
    li {
      float: left;
      line-height: 40px;
      span {
        padding: 0 10px;
      }
      a{
        color: #333;
      }
    }
  }
}
.content {
  width: 960px;
  min-height: 500px;
  margin: 0 auto;
  padding-top: 20px;
  //padding: 20px;
  //background: #fff;
  .leftBox{
    display: inline-block;
    width: 220px;
    .title{
      width: 100%;
      height: 40px;
      line-height: 40px;
      color: #fff;
      margin-bottom: 10px;
    }
    .imgBox{
      li{
        cursor: pointer;
        height: auto;
        .img{
          width: 220px;
          height: 145px;
        }
        .journalisTitle{
          padding: 5px 0;
        }
        .journalisTitle:hover{
          color: #a3bdff;
        }
      }

    }
  }
  .rightBox{
    display: inline-block;
    width: 720px;
    vertical-align: top;
    margin-left: 20px;
    color: #333;
    .rTitle{
      position: relative;
      line-height: 38px;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #838383;
      span{
        position: absolute;
        right: 0;
        font-weight: normal;
        font-size: 14px;
        cursor: pointer;
      }
      span:hover{
        color: #a3bdff;
      }
    }
    .dl{
      margin-bottom: 20px;
      .dd{
        height: 32px;
        line-height: 32px;
        padding-left: 15px;
        .name{
          display: inline-block;
          width: 80%;
          float: left;
          cursor: pointer;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: normal;
        }
        .name:hover{
          color: #a3bdff;
        }
        .time{
          display: inline-block;
          width: 20%;
          float: right;
          text-align: right;
        }
      }
    }
  }
  .listBox{
    min-height: 430px;
    li {
      position: relative;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      color: #545454;
      font-size: 14px;
      .circular {
        position: absolute;
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #1e3c5a;
        border-radius: 5px;
        top: 23px;
      }
      .liText {
        display: inline-block;
        max-width: 800px;
        height: 30px;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 15px;
      }
      .liText:hover{
        color: #a3bdff;
        border-bottom: 1px solid #a3bdff;
      }
      .liTime {
        float: right;
      }
    }

  }


  .pagination {
    border-top: 1px solid #d2d2d2;
    padding-top: 20px;
    margin-top: 20px;
  }
  .title {
    color: #1e3c5a;
    font-size: 26px;
    text-align: center;
    line-height: 80px;
    font-weight: bold;
  }
  .title_m {
    text-align: center;
    border-bottom: 1px solid #d7d7d7;
    padding: 20px;
    font-size: 14px;
    span {
      margin-right: 30px;
    }
  }
  .ql-editor {
    line-height: 30px;
  }
}
.el-pager .active {
  color: #1e3c5a !important;
}
</style>
